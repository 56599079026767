// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-tsx": () => import("/opt/build/repo/packages/website/src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/packages/website/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/opt/build/repo/packages/website/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("/opt/build/repo/packages/website/src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-careers-tsx": () => import("/opt/build/repo/packages/website/src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-clients-tsx": () => import("/opt/build/repo/packages/website/src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-contact-tsx": () => import("/opt/build/repo/packages/website/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/packages/website/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-opensource-tsx": () => import("/opt/build/repo/packages/website/src/pages/opensource.tsx" /* webpackChunkName: "component---src-pages-opensource-tsx" */),
  "component---src-pages-privacy-tsx": () => import("/opt/build/repo/packages/website/src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-projects-slps-index-tsx": () => import("/opt/build/repo/packages/website/src/pages/projects/slps/index.tsx" /* webpackChunkName: "component---src-pages-projects-slps-index-tsx" */),
  "component---src-pages-projects-slps-privacy-tsx": () => import("/opt/build/repo/packages/website/src/pages/projects/slps/privacy.tsx" /* webpackChunkName: "component---src-pages-projects-slps-privacy-tsx" */),
  "component---src-pages-services-backend-tsx": () => import("/opt/build/repo/packages/website/src/pages/services/backend.tsx" /* webpackChunkName: "component---src-pages-services-backend-tsx" */),
  "component---src-pages-services-frontend-tsx": () => import("/opt/build/repo/packages/website/src/pages/services/frontend.tsx" /* webpackChunkName: "component---src-pages-services-frontend-tsx" */),
  "component---src-pages-services-index-tsx": () => import("/opt/build/repo/packages/website/src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-mobile-tsx": () => import("/opt/build/repo/packages/website/src/pages/services/mobile.tsx" /* webpackChunkName: "component---src-pages-services-mobile-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("/opt/build/repo/packages/website/src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-pages-work-tsx": () => import("/opt/build/repo/packages/website/src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

