import React from "react";
import Helmet from "react-helmet";

import config from "../../config";

const SEO = ({ title }: { title: string }) => (
  <Helmet htmlAttributes={{ lang: "en-US" }}>
    <title>{title}</title>
    <meta
      name="viewport"
      content="initial-scale=1.0, width=device-width"
      key="viewport"
    />
    <meta charSet="utf-8" />
    <meta httpEquiv="refresh" />
    <meta httpEquiv="x-ua-compatible" content="ie=edge" />
    <meta name="description" content={config.description} />
    <meta name="author" content={config.title} />
    <meta
      name="keywords"
      content="fregmaa,frontend development,mobile development"
    />
    <meta property="og:title" content={config.title} />
    <meta property="og:description" content={config.title} />
    <meta property="og:type" content="profile" />
    <meta property="og:url" content={config.siteUrl} />
    <meta name="theme-color" content={config.theme} />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content={config.twitter} />
    <meta name="twitter:creator" content={config.twitter} />
    <link rel="canonical" href={config.siteUrl} />
  </Helmet>
);

export default SEO;
