import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";

import "./navbar.scss";

function Navbar() {
  const [toggle, setToggle] = useState<boolean>(false);

  const menuRef = useRef<HTMLDivElement>(null);

  const onCloseNav = () => {
    if (menuRef.current) {
      menuRef.current.classList.remove("menu-open");
    }
  };

  useEffect(() => {
    if (menuRef.current) {
      if (toggle) {
        menuRef.current.classList.add("menu-open");
      } else {
        menuRef.current.classList.remove("menu-open");
      }
    }
  }, [toggle]);

  return (
    <div className="nav" id="header">
      <div className="dropdownBackground">
        <span className="arrow" />
      </div>
      <div className="menu" onClick={() => setToggle(!toggle)}>
        <button id="menu__btn" aria-label="Menu" />
      </div>
      <div id="headerNav" ref={menuRef}>
        <Link to="/" id="header-logo">
          Fr<span id="header-logo__slash">e</span>gmaa
        </Link>
        <nav id="nav">
          <Link onClick={onCloseNav} className="nav-link" to="/services">
            Services
          </Link>
          <Link onClick={onCloseNav} className="nav-link" to="/work">
            Work
          </Link>
          <Link onClick={onCloseNav} className="nav-link" to="/about">
            About
          </Link>
          <Link onClick={onCloseNav} className="nav-link" to="/blog">
            Blog
          </Link>
          <Link
            onClick={onCloseNav}
            className="nav-link"
            id="nav-hire-us"
            to="/contact"
          >
            Hire Us
          </Link>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
