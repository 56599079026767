// Configuration File

module.exports = {
  title: `Fregmaa  - Web development experts`,
  description: `We are software development studio based in Mumbai, India.`,
  siteUrl: `https://fregmaa.com/`,
  twitter: `@fregmaa`,
  theme: `#00e0d0`,
  author: `Yoegsh Kotadiya`,
};
