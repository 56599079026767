/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as React from "react";
// Import all fonts at top level
import "typeface-rajdhani";
import "typeface-poppins";

import "intersection-observer";

import Layout from "./src/components/layout";

// eslint-disable
console.log("We are %c Fregmaa @fregmaa", "color: #ff0000; font-size: 18px;");
console.log(
  "%c Did you find something Interesting? Tell us dev@fregmaa.com",
  "color: #00e0d0; font-size: 16px;"
);

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return React.createElement(Layout, { ...props }, element);
};
