import React, { ReactChild } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import {
  FaFacebookSquare,
  FaGithub,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";

import "./footer.scss";

const SocialLink = ({
  children,
  link,
  ...props
}: {
  children: ReactChild;
  link: string;
}) => (
  <li>
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="footer__nav-social"
      {...props}
    >
      {children}
    </a>
  </li>
);

const NavHeading = ({ name, ...props }: { name: string }) => (
  <li className="footer__nav-item" {...props}>
    <p className="footer__nav-heading">{name}</p>
  </li>
);

const NavLink = ({ to, name, ...props }: { to: string; name: string }) => (
  <li className="footer__nav-item" {...props}>
    <Link to={to} className="footer__nav-link">
      {name}
    </Link>
  </li>
);

const Footer: React.FC = () => {
  const data = useStaticQuery(LogoQuery);

  return (
    <footer className="footer">
      <div className="footer__nav">
        <div className="footer__nav-navigation">
          <div className="footer__copyright">
            <a href="/" className="footer__logo">
              <Img
                fluid={data.fileName.childImageSharp.fluid}
                alt="Fregmaa Logo"
              />
            </a>
            <p className="footer__copy">
              Fr<span id="footer-logo__slash">e</span>gmaa
            </p>
          </div>
          <ul className="footer__nav-social-list">
            <SocialLink
              link="https://www.github.com/fregmaa"
              aria-label="Fregmaa Github Link"
            >
              <FaGithub className="footer__nav-social-icon" />
            </SocialLink>
            <SocialLink
              link="https://www.facebook.com/fregmaa"
              aria-label="Fregmaa Facebook Link"
            >
              <FaFacebookSquare className="footer__nav-social-icon" />
            </SocialLink>
            <SocialLink
              link="https://twitter.com/fregmaa"
              aria-label="Fregmaa Twitter Link"
            >
              <FaTwitter className="footer__nav-social-icon" />
            </SocialLink>
            <SocialLink
              link="https://www.linkedin.com/company/fregmaa"
              aria-label="Fregmaa LinkedIn Link"
            >
              <FaLinkedin className="footer__nav-social-icon" />
            </SocialLink>
            <SocialLink
              link="https://www.youtube.com/channel/UCDER536uwvgdrjreFxO7zjg"
              aria-label="Fregmaa Youtube Link"
            >
              <FaYoutube className="footer__nav-social-icon" />
            </SocialLink>
          </ul>
        </div>
        <div className="footer__nav-navigation">
          <ul className="footer__nav-list">
            <NavHeading name="Overview" />
            <NavLink to="/about" name="About" />
            <NavLink to="/services" name="Services" />
            <NavLink to="/work" name="Our work" />
          </ul>
        </div>
        <div className="footer__nav-navigation">
          <ul className="footer__nav-list">
            <NavHeading name="Connect" />
            <NavLink to="/contact" name="Contact us" />
            <NavLink to="/blog" name="Blog" />
            <NavLink to="/careers" name="Careers" />
            {/* <li className="footer__nav-item">
              <h3 className="footer__nav-link">Subscribe to our News letter</h3>
              <div id="newsletterInput">
                <input
                  type="email"
                  name="newsletter"
                  aria-label="News Letter Input"
                  placeholder="your@email.com"
                  required
                  pattern="email"
                />
                <button id="newsletter-btn" type="submit">
                  Join
                </button>
              </div>
            </li> */}
          </ul>
        </div>
        <div className="footer__nav-navigation">
          <ul className="footer__nav-list">
            <NavHeading name="Resources" />
            <NavLink to="/opensource" name="Open Source" />
            <NavLink to="/privacy" name="Privacy Policy" />
            <li className="footer__nav-item">
              <a href="/sitemap.xml" className="footer__nav-link">
                Sitemap
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="footer__rights">
        <p>
          Copyright &copy; {new Date().getFullYear()} Fregmaa All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

Footer.displayName = "Footer";
export default Footer;

const LogoQuery = graphql`
  query {
    fileName: file(relativePath: { eq: "fregmaa_logo_preview-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
