import React, { ReactChild } from "react";
import { StaticQuery, graphql } from "gatsby";

import "@styles/global.scss";
import "@styles/layout.scss";

import Header from "./Navbar";
import Footer from "./Footer";
import SEO from "./SEO";

const Layout = ({ children }: { children: ReactChild }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <SEO title={data.site.siteMetadata.title} />
        <Header />
        {children}
        <Footer />
      </>
    )}
  />
);

export default Layout;
